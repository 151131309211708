var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.request_done)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(_vm.request_done)?_c('v-alert',{staticClass:"text-center mt-10",attrs:{"type":"warning","dense":""}},[_vm._v(" يرجى العلم فى حالة حذف اى عميل سيتم حذف جميع الأرصدة والشهادة الخاصة به ")]):_vm._e(),(_vm.request_done)?_c('v-card',{staticClass:"mt-10"},[_c('v-card-title',[_c('h2',[_vm._v("العملاء")]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"بحث"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"footer-props":_vm.$store.state.footer_props,"search":_vm.search,"items":_vm.tbody,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.slice(0, item.created_at.indexOf("T")))+" ")]}},{key:"item.block",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text px-1",attrs:{"x-small":"","color":item.block == 0 ? 'success' : 'red lighten-3'}},[_vm._v(" "+_vm._s(item.block == 0 ? "فعال" : "غير فعال")+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","color":"blue","to":{
            name: 'customer_profile',
            query: { user_info_identifier: item.id },
          }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.block_user(item.id, index)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"me-1",attrs:{"small":""}},[_vm._v("block")])],1)]}}],null,true)},[_c('span',[_vm._v("حظر المستخدم")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.get_delete_id(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"me-1",attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("حذف المستخدم")])])]}}],null,true)})],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog.prop),callback:function ($$v) {_vm.$set(_vm.dialog, "prop", $$v)},expression:"dialog.prop"}},[_c('v-card',[_c('v-card-title',[_vm._v("تأكيد الحذف")]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-4"},[_c('h2',{staticClass:"text-center mb-3 font-weight-medium"},[_vm._v(" هل تريد حذف المستخدم ؟ ")]),_c('div',{staticClass:"text-center"},[_c('v-chip',[_vm._v(" اسم المستخدم : "+_vm._s(_vm.dialog.name)+" ")])],1)]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"disabled":_vm.dialog_loading,"loading":_vm.dialog_loading,"color":"error"},on:{"click":_vm.delete_customer}},[_vm._v(" حذف ")]),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){_vm.dialog.prop = false}}},[_vm._v(" الغاء ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }