<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!request_done"></v-skeleton-loader>
    <v-alert v-if="request_done" type="warning" class="text-center mt-10" dense>
      يرجى العلم فى حالة حذف اى عميل سيتم حذف جميع الأرصدة والشهادة الخاصة به
    </v-alert>
    <v-card class="mt-10" v-if="request_done">
      <v-card-title>
        <h2>العملاء</h2>
        <v-spacer></v-spacer>
        <v-text-field label="بحث" v-model="search"></v-text-field>
      </v-card-title>
      <v-data-table
        :mobile-breakpoint="0"
        :footer-props="$store.state.footer_props"
        :search="search"
        :items="tbody"
        :headers="headers"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
        </template>
        <!--/ template -->

        <template v-slot:[`item.block`]="{ item }">
          <v-chip
            x-small
            class="white--text px-1"
            :color="item.block == 0 ? 'success' : 'red lighten-3'"
          >
            {{ item.block == 0 ? "فعال" : "غير فعال" }}
          </v-chip>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <v-btn
            text
            color="blue"
            class="text-capitalize"
            :to="{
              name: 'customer_profile',
              query: { user_info_identifier: item.id },
            }"
          >
            {{ item.name }}
          </v-btn>
        </template>

        <template v-slot:[`item.actions`]="{ item, index }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="block_user(item.id, index)"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="me-1" small>block</v-icon>
              </v-btn>
            </template>
            <span>حظر المستخدم</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="get_delete_id(item)" icon v-bind="attrs" v-on="on">
                <v-icon class="me-1" small>delete</v-icon>
              </v-btn>
            </template>
            <span>حذف المستخدم</span>
          </v-tooltip>
        </template>
        <!--/ template -->
      </v-data-table>
    </v-card>

    <v-dialog max-width="500" v-model="dialog.prop">
      <v-card>
        <v-card-title>تأكيد الحذف</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <h2 class="text-center mb-3 font-weight-medium">
            هل تريد حذف المستخدم ؟
          </h2>
          <div class="text-center">
            <v-chip> اسم المستخدم : {{ dialog.name }} </v-chip>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="dialog_loading"
            :loading="dialog_loading"
            @click="delete_customer"
            color="error"
          >
            حذف
          </v-btn>
          <v-btn @click="dialog.prop = false" color="success" text>
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--/ dialog -->
  </v-container>
</template>

<script>
export default {
  name: "Customers",
  data() {
    return {
      dialog: {
        prop: false,
        name: "",
        id: null,
      },
      search: "",
      dialog_loading: false,
      request_done: false,
      headers: [
        {
          value: "name",
          text: "الأسم",
          align: "center",
        },
        {
          value: "phone",
          text: "رقم الهاتف",
          align: "center",
        },
        {
          value: "block",
          text: "الحالة",
          align: "center",
        },
        {
          value: "created_at",
          text: "تاريخ التسجيل",
          align: "center",
        },
        {
          value: "actions",
          text: "اجرءات",
          align: "center",
        },
      ],
      tbody: [],
    };
  },
  methods: {
    get_customers() {
      this.$store
        .dispatch("public__request", {
          config: {
            method: "get",
            url: "users",
          },
        })
        .then((res) => {
          this.tbody = res.data.data;
          this.request_done = true;
          console.log(res.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    get_delete_id(item) {
      this.dialog.name = item.name;
      this.dialog.id = item.id;
      this.dialog.prop = true;
    },
    delete_customer() {
      this.dialog_loading = true;
      this.$store
        .dispatch("public__request", {
          config: {
            method: "delete",
            url: `users/${this.dialog.id}`,
          },
        })
        .then((res) => {
          this.get_customers();
          this.dialog_loading = false;
          this.dialog = {
            prop: false,
            name: "",
            id: null,
          };
        });
    },
    block_user(id, ind) {
      this.$store
        .dispatch("public__request", {
          config: {
            method: "put",
            url: `block/${id}`,
          },
        })
        .then((res) => {
          let item = this.tbody[ind].block;
          this.$set(this.tbody[ind], "block", item == 0 ? 1 : 0);
        })
        // .catch((err) => console.log(err.response));
    },
  },
  mounted() {
    this.get_customers();
  },
};
</script>
